.containerGral {
    padding: 2rem;
    /* width: 85%; */
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }

 

  html,
  body {
    height: 100%;
  }

  @media (min-width: 640px) {
    table {
      display: inline-table !important;
    }

    thead tr:not(:first-child) {
      display: none;
    }
  }

  td:not(:last-child) {
    border-bottom: 0;
  }

  th:not(:last-child) {
    border-bottom: 2px solid rgba(0, 0, 0, .1);
  }